export const ARCHIVE_ACCOUNT = "ARCHIVE_ACCOUNT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const PAGE = "PAGE";
export const FOLDER_CREATED = "FOLDER_CREATED";
export const FILE_UPLOADED = "FILE_UPLOADED";
export const FOLDER_NAME = "FOLDER_NAME";
export const FOLDER_LIST = "FOLDER_LIST";
export const FILE_LIST = "FILE_LIST";
export const UPPY_SUCCESS = "UPPY_SUCCESS";
export const UPPY_FAILED = "UPPY_FAILED";
export const UPPY_BATCHID = "UPPY_BATCHID ";
export const VIDEO_LIST = "VIDEO_LIST";
export const EDIT_VIDEO = "EDIT_VIDEO" ;
export const USER_OBJ = "USER_OBJ";
export const FILTER_TYPE = "FILTER_TYPE";
export const ACCESS_IN = "ACCESS_IN";
export const ACCESS_OUT = "ACCESS_OUT";
export const DBFOLDER_LIST = "DBFOLDER_LIST";
export const PUBLIC_VIDEOS = "PUBLIC_VIDEOS";
export const APP_PLANS = "APP_PLANS";
export const USER_PLANS = "USER_PLANS";
